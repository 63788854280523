.left .MuiListItemText-primary {
    background-color:#BADDE0;
    border-radius: 5px;
    padding: 10px;
    display: inline-block;  
}

.right .MuiListItemText-primary {
    background-color:#79d9e2;
    border-radius: 5px;
    padding: 10px;
    display: inline-block;
}

.list-message-chat {
    max-height: 70vh;
    min-height: 500px;
}

.fab-chat {
    width: 56px;
    height: 56px;
}

.picture-chat {
    width: 40%;
    object-fit: contain;
}

.picture-chat:hover {
    filter: drop-shadow(1px 1px 7px #000000);
}

.picture-dialogue-chat {
    max-width: 80%;
    max-height: 70%;
    object-fit: contain;
}


@media (max-width: 900px) {
    .list-message-chat {
        max-height: 70vh;
    }

    .picture-chat {
        width: 60%;
        object-fit: contain;
    }
}

@media (max-width: 700px) {
    .list-user-chat {
        display: flex;
        flex-direction: column;
        justify-items: center;
        word-break: break-word;
    }

    .avatar-user-chat {
        justify-content: center;
    }
}

@media (max-width: 550px) {
    .fab-chat {
        width: 40px;
        height: 40px;
    }
}