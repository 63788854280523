.title-paiementtracking,
.step-title-paiementtracking,
.subTitle-adstracking,
.details-adstracking {
  text-align: center;
}

.container-steps-paiementtracking {
  display: flex;
  justify-content: center;
}

.title-paiementtracking {
  padding-left: 24px;
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
} 

.subTitle-adstracking {
  font-size: 30px;
  font-weight: 500;
  line-height: 34px;
}

.details-adstracking {
  font-size: 20px;
  font-weight: 300;
  line-height: 26px;
}

.step-paiementtracking {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  font-weight: bold;
}

.step-check-paiementtracking {
  margin-bottom: 5px;
  position: relative;
  display: flex;
  justify-content: center;
}

.step-check-paiementtracking > * {
  font-size: 50px;
}

.step-paiementtracking.success, .step-check-paiementtracking.success {
  color: green;
}

.step-paiementtracking.error, .step-title-paiementtracking.error {
  color: red;
}

@media (max-width: 1200px) {
  .container-steps-paiementtracking {
    flex-wrap: wrap;
    row-gap: 20px;
  }
  .step-title-paiementtracking {
    width: 130px;
  }
}

@media (max-width: 1000px) {
  .step-paiementtracking {
    margin: 0 5px;
  }

  .step-title-paiementtracking {
    font-size: 14px;
    width: 100px;
  }
}

@media (max-width: 900px) {
  .step-paiementtracking {
    margin: 0 10px;
  }

  .step-title-paiementtracking {
    font-size: 16px;
    width: auto;
  }
}

@media (max-width: 480px) {
  .step-paiementtracking {
    margin: 0 5px;
  }

  .step-title-paiementtracking {
    font-size: 14px;
    width: 100px;
  }
}