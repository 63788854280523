.title-adstracking,
.step-title-adstracking,
.subTitle-adstracking,
.details-adstracking {
  text-align: center;
}

.container-steps-adstracking {
  display: flex;
  justify-content: center;
}

.step-adstracking {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}

.step-check-adstracking {
  margin-bottom: 5px;
  position: relative;
}

.step-check-adstracking.active {
  color: green;
}

.step-title-adstracking.active {
  font-weight: bold;
  color: green;
}

@media (max-width: 1200px) {
  .container-steps-adstracking {
    flex-wrap: wrap;
    row-gap: 20px;
  }
  .step-title-adstracking {
    width: 130px;
  }
}

@media (max-width: 1000px) {
  .step-adstracking {
    margin: 0 5px;
  }

  .step-title-adstracking {
    font-size: 14px;
    width: 100px;
  }
}

@media (max-width: 900px) {
  .step-adstracking {
    margin: 0 10px;
  }

  .step-title-adstracking {
    font-size: 16px;
    width: auto;
  }
}

@media (max-width: 480px) {
  .step-adstracking {
    margin: 0 5px;
  }

  .step-title-adstracking {
    font-size: 14px;
    width: 100px;
  }
}